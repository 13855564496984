
import { configureStore } from "@reduxjs/toolkit";

import designsReducer from "./slices/design";
import orderReducer from "./slices/order";
import variantReducer from "./slices/variant";

const store = configureStore({
    reducer: {
        order: orderReducer,
        designs: designsReducer,
        variants: variantReducer,
    },
    devTools: process.env.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
