/**
 *  Format price to EUR
 *
 * **Note:** If given price is falsy, it will return `",- €"`
 *
 * @param price - price to format
 * @returns formatted price
 */
export function formatPrice (price: number): string {
    if (!price) {
        return ",- €";
    }

    return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
    }).format(price);
}
