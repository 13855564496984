import React, { ReactElement } from "react";
import { Container } from "semantic-ui-react";

import DesignPageContinueButton from "./DesignPageContinueButton";


/**
 *  Handles the controll buttons in the side menu, when the user is on the design page.
 *
 * @returns The design controll component
 */
export default function DesignPageControlls (): ReactElement {

    return (
        <Container textAlign="right">
            <DesignPageContinueButton/>
        </Container>
    );
}
