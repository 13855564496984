
/**
 * Struct which helps handling errors that occur, when communicating with the backend service.
 */
export type ServiceError = {
    /**
     * Status code of the error response.
     */
    statusCode: number;

    /**
     * Error message to be displayed to the user.
     */
    message: string;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isServiceError (error: any): error is ServiceError {
    return error?.statusCode !== undefined && error?.message !== undefined;
}
