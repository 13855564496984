import React, { ReactElement } from "react";
import { Container, Header } from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";

export default function GeneralTermsConditions (): ReactElement {
    return <PageTemplate>
        <Container
            text
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >
            <Header as="h1">AGB</Header>

            <p>
                Hirnstiff Abdulhanan Oesterle Schroeder GbR <br/>
                Im Mönchgraben 26/1 <br/>
                75397 Simmozheim <br/>
                E-Mail-Adresse: info@knopfdruck.eu
            </p>
            <br/>

            <Header as="h2">Übersicht</Header>

            <p>
                Die Webseite https://knopfdruck.eu wird von Hirnstiff Abdulhanan Oesterle Schroeder GbR betrieben.
                Überall auf der Webseite beziehen sich die
                Begriffe {"\""}wir{"\""}, {"\""}uns{"\""} und {"\""}unsere/e{"\""} auf Hirnstiff Abdulhanan Oesterle
                Schroeder GbR. Hirnstiff Abdulhanan Oesterle
                Schroeder GbR bietet diese Website, einschließlich aller Informationen, Tools und Dienste, die auf
                dieser Website verfügbar sind, Ihnen, dem Benutzer, unter der Bedingung an, dass Sie alle hier
                angegebenen Bedingungen, Konditionen, Richtlinien und Hinweise akzeptieren.
            </p>

            <p>
                Wenn Sie unsere Website besuchen und/oder etwas bei uns kaufen, nutzen Sie unseren {"\""}Dienst{"\""} und
                erklären sich damit einverstanden, an die folgenden Allgemeinen Geschäftsbedingungen
                ({"\""}Allgemeine Geschäftsbedingungen{"\""}, {"\""}Bedingungen{"\""}) gebunden zu sein, einschließlich
                der zusätzlichen Geschäftsbedingungen und Richtlinien, die hierin erwähnt werden und/oder per Hyperlink
                verfügbar sind. Diese Allgemeinen Geschäftsbedingungen gelten für alle Benutzer der Website,
                insbesondere Benutzer, die Besucher, Anbieter, Kunden, Händler und/oder Verfasser von Inhalten sind.
            </p>

            <p>
                Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch, bevor Sie auf unsere Website
                zugreifen oder diese benutzen. Durch den Zugriff auf oder die Nutzung eines jeglichen Teils der Website
                erklären Sie sich mit diesen Allgemeinen Geschäftsbedingungen einverstanden. Sind Sie nicht mit allen
                Geschäftsbedingungen dieser Vereinbarung einverstanden, dürfen Sie nicht auf die Website zugreifen oder
                irgendwelche Dienste nutzen. Wenn diese Allgemeinen Geschäftsbedingungen als Angebot betrachtet werden,
                beschränkt sich die Annahme ausdrücklich auf diese Allgemeinen Geschäftsbedingungen.
            </p>

            <p>
                Alle neuen Funktionen oder Tools, die zum aktuellen Service hinzugefügt werden, unterliegen ebenfalls den
                Allgemeinen Geschäftsbedingungen. Sie können die aktuelle Version der Allgemeinen Geschäftsbedingungen
                jederzeit auf dieser Seite einsehen. Wir behalten uns das Recht vor, Teile dieser Allgemeinen
                Geschäftsbedingungen durch Veröffentlichung von Updates und/oder Änderungen unserer Website zu
                aktualisieren, zu ändern oder zu ersetzen. Es liegt in Ihrer Verantwortung, diese Seite regelmäßig auf
                mögliche Änderungen zu überprüfen. Indem Sie die Website nach der Veröffentlichung von irgendwelchen
                Änderungen weiterhin nutzen oder weiterhin darauf zugreifen, gilt dies als Annahme dieser Änderungen.
            </p>

            <p>
                Shopify stellt uns die Online-E-Commerce-Plattform zur Verfügung, die es uns ermöglicht, unsere
                Produkte und Dienstleistungen an Sie zu verkaufen.
            </p>
            <br/>


            <Header as="h2">Abschnitt 1 - Bedingungen für den Service</Header>

            <p>
                Mit der Zustimmung zu diesen Allgemeinen Geschäftsbedingungen erklären Sie, dass Sie in dem Land Ihres
                Wohnsitzes mindestens volljährig sind oder dass Sie in dem Land Ihres Wohnsitz volljährig sind und uns
                Ihre Zustimmung gegeben haben, dass Ihre minderjährigen Angehörigen diese Website nutzen dürfen.
            </p>
            <p>
                Sie dürfen unsere Produkte weder für illegale oder nicht autorisierte Zwecke nutzen noch dürfen Sie
                durch die Nutzung der Serviceleistung gegen Gesetze in Ihrer Gerichtsbarkeit verstoßen (einschließlich,
                aber nicht beschränkt auf Urheberrechtsgesetze).
            </p>
            <p>
                Sie dürfen keine Würmer oder Viren oder sonstigen Code destruktiver Art übertragen.
            </p>
            <p>
                Der Verstoß gegen oder die Verletzung irgendeiner dieser Bedingungen führt zur sofortigen Kündigung
                Ihrer Serviceleistungen.
            </p>
            <br/>


            <Header as="h2">Abschnitt 2 - Allgemeine Bedingungen</Header>

            <p>
                Wir behalten uns das Recht vor, einer Person die Serviceleistung jederzeit aus beliebigem Grund zu
                verweigern.
            </p>
            <p>
                Sie nehmen zur Kenntnis, dass Ihre Informationen (außer Kreditkarteninformationen) unverschlüsselt
                übertragen werden können und (a) Übertragungen über verschiedene Netzwerke sowie (b) Änderungen
                beinhalten können, um den technischen Anforderungen von verbundenen Netzwerken oder Geräten zu
                entsprechen und sich an diese anzupassen. Kreditkarteninformationen werden bei der Übertragung über
                Netzwerke immer verschlüsselt.
            </p>
            <p>
                Sie verpflichten sich, ohne ausdrückliche schriftliche Genehmigung von uns keinen Teil der
                Serviceleistung, die Nutzung der Serviceleistung oder den Zugriff auf die Serviceleistung oder auf
                jegliche Kontakte auf der Website, durch die die Serviceleistung angeboten wird, zu reproduzieren, zu
                vervielfältigen, zu kopieren, zu verkaufen, weiterzuverkaufen oder zu verwerten.
            </p>
            <p>
                Die in dieser Vereinbarung verwendeten Überschriften dienen nur der Übersichtlichkeit und schränken
                diese Bedingungen nicht ein oder wirken sich anderweitig auf sie aus.
            </p>
            <br/>


            <Header as="h2">Abschnitt 3 - Genauigkeit, Vollständigkeit und Rechtzeitigkeit der Informationen</Header>

            <p>
                Wir sind nicht verantwortlich, wenn die auf dieser Seite zur Verfügung gestellten Informationen nicht
                genau, vollständig oder aktuell sind. Das Material auf dieser Website dient nur der allgemeinen
                Information und sollte nicht als alleinige Grundlage für Entscheidungen herangezogen werden, ohne
                primäre, genauere, vollständigere oder aktuellere Informationsquellen zu prüfen. Jegliches Vertrauen
                in das Material auf dieser Website geschieht auf eigene Verantwortung.
            </p>
            <p>
                Diese Seite enthält möglicherweise gewisse historische Informationen. Historische Informationen sind
                nicht unbedingt aktuell und werden lediglich zu Ihrer Orientierung bereitgestellt. Wir behalten uns
                das Recht vor, Inhalte auf dieser Website jederzeit zu ändern, sind aber nicht verpflichtet,
                irgendwelche Informationen auf unserer Website zu aktualisieren. Sie stimmen zu, dass Sie
                verantwortlich dafür sind, Änderungen auf unserer Website zu überwachen.
            </p>

            <Header as="h2">Abschnitt 4 - Änderungen an der Leistung und den Preisen</Header>
            <p>
                Die Preise für unsere Produkte können ohne vorherige Ankündigung geändert werden.
            </p>
            <p>
                Wir behalten uns das Recht vor, die Serviceleistung (oder einen jeglichen Teil oder Inhalt davon) ohne
                Ankündigung jederzeit zu ändern oder zu beenden.
            </p>
            <p>
                Shopify ist weder Ihnen noch Dritten gegenüber haftbar für Änderungen, Preisänderungen, Sperrung oder
                Einstellung des Serviceleistung.
            </p>

            <Header as="h2">Abschnitt 5 - Produkte oder Serviceleistungen (falls zutreffend)</Header>
            <p>
                Gewisse Produkte oder Serviceleistungen sind möglicherweise nur online über die Website erhältlich.
                Diese Produkte oder Serviceleistungen sind unter Umständen nur in begrenzten Mengen vorhanden und
                unterliegen nur der Rückgabe oder dem Umtausch gemäß unseren Rückgaberichtlinien.
            </p>
            <p>
                Wir haben uns bemüht, die Farben und Bilder unserer Produkte, die im Shop erscheinen, so genau wie
                möglich darzustellen. Wir können nicht garantieren, dass Ihr Computerbildschirm jede Farbe korrekt
                anzeigt.
            </p>
            <p>
                Wir behalten uns das Recht vor, sind aber nicht verpflichtet, den Verkauf unserer Produkte oder
                Serviceleistungen auf gewisse Personen, geografische Regionen oder Gerichtsbarkeiten zu beschränken.
                Wir können dieses Recht von Fall zu Fall ausüben. Wir behalten uns das Recht vor, die Mengen der von
                uns angebotenen Produkte oder Serviceleistungen zu begrenzen. Alle Produktbeschreibungen oder
                Produktpreise können jederzeit ohne Vorankündigung und nach unserem alleinigen Ermessen geändert
                werden. Wir behalten uns das Recht vor, jedes Produkt jederzeit aus dem Angebot zu nehmen. Jedes
                Angebot für ein Produkt oder eine Serviceleistung, das auf dieser Website gemacht wird, ist ungültig,
                wo es verboten ist.
            </p>
            <p>
                Wir garantieren nicht, dass die Qualität von Produkten, Serviceleistungen, Informationen oder anderen
                Materialien, die von Ihnen gekauft oder erworben wurden, Ihren Erwartungen entspricht, oder dass
                Fehler in der Serviceleistung korrigiert werden.
            </p>


            <Header as="h2">Abschnitt 6 - Eigentumsvorbehalt</Header>
            <p>
                Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung des Kaufpreises vor.
            </p>


            <Header as="h2">Abschnitt 7 - Richtigkeit der Rechnungs- und Kontoinformationen</Header>
            <p>
                Wir behalten uns das Recht vor, jegliche Bestellung, die Sie bei uns aufgeben, abzulehnen. Wir können
                nach eigenem Ermessen die Abnahmemengen pro Person, pro Haushalt oder pro Bestellung begrenzen oder
                stornieren. Diese Einschränkungen können Bestellungen umfassen, die von oder unter demselben
                Kundenkonto oder derselben Kreditkarte aufgegeben wurden und/oder Bestellungen, die dieselbe
                Rechnungs- und/oder Lieferadresse verwenden. Falls wir eine Bestellung ändern oder stornieren, werden
                wir versuchen, Sie zu benachrichtigen, indem wir die E-Mail und/oder die Rechnungsadresse/Telefonnummer
                kontaktieren, die zum Zeitpunkt der Bestellung angegeben wurde. Wir behalten uns das Recht vor,
                Bestellungen einzuschränken oder zu verbieten, die nach unserem alleinigen Ermessen den Anschein
                erwecken, von Händlern, Wiederverkäufern oder Distributoren aufgegeben worden zu sein.
            </p>
            <p>
                Sie stimmen zu, aktuelle, vollständige und richtige Kauf- und Kontoinformationen für alle in unserem
                Shop getätigten Käufe anzugeben. Sie stimmen zu, Ihr Konto und andere Informationen, einschließlich
                Ihrer E-Mail-Adresse und Kreditkartennummern und Ablaufdaten, umgehend zu aktualisieren, damit wir
                Ihre Transaktionen abschließen und Sie bei Bedarf kontaktieren können.
            </p>
            <p>
                Für weitere Details lesen Sie bitte unsere Rückgaberichtlinien.
            </p>


            <Header as="h2">Abschnitt 8 - Zusätzliche Tools</Header>
            <p>
                Wir bieten Ihnen möglicherweise Zugriff auf Tools von Drittanbietern, die wir weder überwachen noch
                kontrollieren oder beeinflussen können.
            </p>
            <p>
                Sie stimmen zu, dass wir den Zugriff auf diese Tools wie {"\""}besehen{"\""} und
                {"\""}wie verfügbar{"\""} ohne jegliche Garantien, Zusicherungen oder Bedingungen jeglicher Art und
                ohne jegliche Befürwortung zur Verfügung stellen. Wir übernehmen keinerlei Haftung, die sich aus Ihrer
                Nutzung von zusätzlichen Drittanbieter-Tools ergibt oder damit zusammenhängt.
            </p>
            <p>
                Jegliche Nutzung von zusätzlichen Tools, die über die Website angeboten werden, erfolgt auf eigene
                Verantwortung und nach eigenem Ermessen, und Sie sollten sicherstellen, dass Sie mit den
                Bedingungen, zu denen die Tools von dem/den jeweiligen Drittanbieter/n zur Verfügung gestellt
                werden, vertraut sind und ihnen zustimmen.
            </p>
            <p>
                Wir werden möglicherweise in Zukunft auch neue Serviceleistungen und/oder Funktionen über die Website
                anbieten (einschließlich der Veröffentlichung neuer Tools und Ressourcen). Solche neuen Funktionen
                und/oder Serviceleistungen unterliegen ebenfalls diesen Allgemeinen Geschäftsbedingungen.
            </p>


            <Header as="h2">Abschnitt 9 - Drittanbieter-Links</Header>
            <p>
                Gewisse Inhalte, Produkte und Serviceleistungen, die über unseren Service erhältlich sind, können
                Materialien von Drittanbietern umfassen.
            </p>
            <p>
                Es kann sein, dass Drittanbieter-Links auf dieser Website Sie zu Drittanbieter-Websites führen, die
                nicht mit uns zusammenarbeiten. Wir sind nicht verantwortlich für die Prüfung oder Bewertung des
                Inhalts oder seiner Richtigkeit und wir übernehmen keine Garantie und keine Haftung oder Verantwortung
                für Materialien oder Websites oder für andere Materialien, Produkte oder Serviceleistungen von
                Drittanbietern.
            </p>
            <p>
                Wir haften nicht für Schäden, die im Zusammenhang mit dem Kauf oder der Benutzung von Waren,
                Serviceleistungen, Ressourcen, Inhalten oder anderen Transaktionen in Verbindung mit
                Drittanbieter-Websites entstehen. Bitte lesen Sie die Richtlinien und Praktiken des Drittanbieters
                sorgfältig durch und stellen Sie sicher, dass Sie diese verstehen, bevor Sie eine Transaktion
                durchführen. Reklamationen, Ansprüche, Bedenken oder Fragen zu Produkten von Drittanbietern sollten
                an den Drittanbieter gerichtet werden.
            </p>


            <Header as="h2">Abschnitt 10 - Benutzerkommentare, Feedback und andere Einsendungen</Header>
            <p>
                Wenn Sie auf unsere Aufforderung hin bestimmte Einsendungen (z. B. Wettbewerbsbeiträge) oder ohne
                Aufforderung durch uns kreative Ideen, Anregungen, Vorschläge, Pläne oder andere Materialien online,
                per E-Mail, per Post oder auf andere Weise (zusammenfassend als {"\""}Kommentare{"\""} bezeichnet)
                einsenden, erklären Sie sich damit einverstanden, dass wir Kommentare, die Sie an uns weiterleiten,
                jederzeit und ohne Einschränkung bearbeiten, vervielfältigen, veröffentlichen, verbreiten, übersetzen
                und auf andere Weise in einem beliebigen Medium verwenden dürfen. Wir sind und werden nicht
                verpflichtet sein, (1) Kommentare vertraulich zu behandeln, (2) eine Vergütung für Kommentare zu zahlen
                oder (3) auf Kommentare zu reagieren.
            </p>
            <p>
                Wir können Inhalte, die wir nach eigenem Ermessen als rechtswidrig, beleidigend, bedrohend,
                verleumderisch, diffamierend, pornografisch, obszön oder anderweitig anstößig oder als das geistige
                Eigentum irgendeiner Partei oder diese Allgemeinen Geschäftsbedingungen verletzend einstufen,
                überwachen, bearbeiten oder entfernen, sind aber nicht dazu verpflichtet.
            </p>
            <p>
                Sie stimmen zu, dass Ihre Kommentare keine Rechte Dritter verletzen, unter anderem Urheber-, Marken-,
                Datenschutz- oder Persönlichkeitsrechte oder andere persönliche Rechte oder Eigentumsrechte. Sie
                stimmen ferner zu, dass Ihre Kommentare kein verleumderisches, diffamierendes oder anderweitig
                rechtswidriges, beleidigendes, hasserfülltes oder obszönes Material oder Computerviren oder andere
                Malware enthalten, die den Betrieb der Services oder einer zugehörigen Website oder Anwendung in
                irgendeiner Weise beeinträchtigen könnten. Sie dürfen keine falsche E-Mail-Adresse verwenden, sich als
                eine andere Person ausgeben oder uns oder Dritte in Bezug auf die Herkunft von Kommentaren in die Irre
                führen. Für Ihre Kommentare und deren Richtigkeit tragen Sie die alleinige Verantwortung. Für
                Kommentare, die von Ihnen oder Dritten veröffentlicht wurden, sind wir weder verantwortlich noch
                haftbar.
            </p>


            <Header as="h2">Abschnitt 11 - Personenbezogene Daten</Header>
            <p>
                Die Übermittlung von personenbezogenen Daten über den Shop unterliegt unserer Datenschutzerklärung.
                Lesen Sie dafür unsere Datenschutzerklärung.
            </p>


            <Header as="h2">Abschnitt 12 - Fehler, Ungenauigkeiten und Auslassungen</Header>
            <p>
                Gelegentlich kann es vorkommen, dass unsere Website oder unsere Serviceleistung typografische Fehler,
                Ungenauigkeiten oder Auslassungen in Bezug auf Produktbeschreibungen, Preisgestaltung, Werbeaktionen,
                Angebote, Produktversandgebühr, Lieferzeit und Verfügbarkeit enthalten. Wir behalten uns das Recht vor,
                jederzeit und ohne vorherige Ankündigung (auch nachdem Sie Ihre Bestellung aufgegeben haben) jegliche
                Fehler, Ungenauigkeiten und Auslassungen zu korrigieren und Informationen zu ändern oder zu
                aktualisieren oder Bestellungen zu stornieren, wenn eine Information in Bezug auf die Serviceleistung
                oder auf irgendeiner zugehörigen Website falsch ist.
            </p>
            <p>
                Wir übernehmen keine Verpflichtung zur Aktualisierung, Änderung oder Klarstellung von Informationen in
                Bezug auf die Serviceleistung oder auf irgendeiner zugehörigen Website, einschließlich, aber nicht
                beschränkt auf, Preisinformationen, es sei denn, dies ist gesetzlich vorgeschrieben. Angeführte
                Aktualisierungsdaten, die in Bezug auf die Serviceleistung oder auf irgendeiner zugehörigen Website
                angegeben werden, sollten nicht als Hinweis darauf verstanden werden, dass alle Informationen in Bezug
                auf die Serviceleistung oder auf irgendeiner zugehörigen Website geändert oder aktualisiert wurden.
            </p>


            <Header as="h2">Abschnitt 13 - Verbotene Anwendungen</Header>
            <p>
                Zusätzlich zu anderen Verboten, die in den Allgemeinen Geschäftsbedingungen dargelegt sind, ist es
                Ihnen untersagt, die Website oder ihren Inhalt für Folgendes zu nutzen:(a) für rechtswidrige Zwecke;
                (b) um andere zur Durchführung oder Teilnahme an ungesetzlichen Handlungen aufzufordern; (c) um gegen
                internationale, bundesstaatliche, provinzielle oder staatliche Vorschriften, Regeln, Gesetze oder
                örtliche Verordnungen zu verstoßen; (d) um unsere Rechte an geistigem Eigentum oder die Rechte an
                geistigem Eigentum anderer zu beeinträchtigen oder zu verletzen; (e) um zu belästigen, zu missbrauchen,
                zu beleidigen, zu verletzen, zu diffamieren, zu verleumden, zu verunglimpfen, einzuschüchtern oder
                aufgrund von Geschlecht, sexueller Orientierung, Religion, ethnischer Zugehörigkeit, Rasse, Alter,
                nationaler Herkunft oder Behinderung zu diskriminieren; (f) falsche oder irreführende Informationen zu
                übermitteln; (g) um Viren oder jede andere Art von schädlichem Code hochzuladen oder zu übertragen,
                die auf irgendeine Weise die Funktionalität oder den Betrieb der Serviceleistung oder irgendeiner
                zugehörigen Website, anderer Websites oder des Internets beeinträchtigen; (h) um persönliche Daten
                anderer zu sammeln oder zu verfolgen; (i) zum Spammen, Phishing, Pharming, Pretexting, Spidering,
                Crawling oder Scraping; (j) für irgendwelche obszönen oder unmoralischen Zwecke; oder (k) um die
                Sicherheitsfunktionen der Serviceleistung oder irgendeiner zugehörigen Website, anderer Websites
                oder des Internets zu stören oder zu umgehen. Wir behalten uns das Recht vor, Ihre Nutzung der
                Serviceleistung oder irgendeiner zugehörigen Website zu beenden, wenn Sie eine der verbotenen
                Anwendungen missachten.
            </p>


            <Header as="h2">Abschnitt 14 - Haftungsausschluss: Haftungsbeschränkung</Header>
            <p>
                Wir übernehmen keine Garantie, Verantwortung oder Gewährleistung dafür, dass die Nutzung unserer
                Serviceleistung ununterbrochen, zeitgerecht, sicher oder fehlerfrei erfolgt.
            </p>
            <p>
                Wir garantieren nicht, dass die Ergebnisse, die durch die Nutzung der Serviceleistung erzielt werden
                können, genau oder zuverlässig sind.
            </p>
            <p>
                Sie stimmen zu, dass wir von Zeit zu Zeit die Serviceleistung auf unbestimmte Zeit entfernen oder
                jederzeit ohne Benachrichtigung an Sie einstellen können.
            </p>
            <p>
                Sie stimmen ausdrücklich zu, dass die Nutzung der Serviceleistung bzw. die Unfähigkeit zur Nutzung der
                Servicedienstleistung auf Ihr alleiniges Risiko erfolgt. Der Service und alle Produkte und
                Serviceleistungen, die Ihnen über den Service angeboten werden Ihnen
                (sofern nicht ausdrücklich von uns angegeben) {"\""}wie besehen{"\""} und {"\""}wie verfügbar{"\""} zur
                Ihrer Nutzung bereitgestellt, und zwar ohne jegliche Zusicherungen, Gewährleistungen oder Bedingungen
                jeglicher Art, weder ausdrücklich noch stillschweigend, einschließlich aller stillschweigenden
                Gewährleistungen oder Bedingungen der Handelsüblichkeit, handelsüblichen Qualität, Eignung für einen
                bestimmten Zweck, Belastbarkeit, Rechtsmängelhaftung oder Nichtverletzung von Rechten Dritter.
            </p>
            <p>
                Auf keinen Fall sind Hirnstiff Abdulhanan Oesterle Schroeder GbR, unsere Direktoren, leitenden
                Angestellten, Mitarbeiter, Tochtergesellschaften, Vertreter, Auftragnehmer, Praktikanten, Lieferanten,
                Dienstanbieter oder Lizenzgeber haftbar für irgendwelche Verletzungen, Verluste, Ansprüche oder
                jegliche direkte, indirekte, zufällige, strafende, besondere oder Folgeschäden jeglicher Art,
                einschließlich, aber nicht beschränkt auf entgangene Gewinne, entgangene Einnahmen, verlorene
                Einsparungen, Datenverlust, Wiederbeschaffungskosten oder ähnliche Schäden, unabhängig davon, ob sie
                auf einem Vertrag, einer unerlaubten Handlung (einschließlich Fahrlässigkeit) oder
                verschuldensunabhängigen Haftung beruhen oder sich sonstwie aus der Nutzung des Dienstes oder von
                Produkten ergeben, die über den Dienst beschafft wurden, oder für andere Ansprüche, die in irgendeiner
                Weise mit der Nutzung des Dienstes oder eines Produkts zusammenhängen, einschließlich, aber nicht
                beschränkt auf Fehler oder Auslassungen in Inhalten oder Verluste oder Schäden jeglicher Art, die
                sich aus der Nutzung des Dienstes oder von Inhalten (oder Produkten) ergeben, die über den Dienst
                veröffentlicht, übertragen oder anderweitig zur Verfügung gestellt wurden, selbst wenn auf deren
                Möglichkeit hingewiesen wurde.
            </p>
            <p>
                Da einige Länder oder Gerichtsbarkeiten den Ausschluss oder die Haftungsbeschränkung für Folgeschäden
                oder zufällige Schäden in diesen Staaten oder Gerichtsbarkeiten nicht zulassen, ist unsere Haftung auf
                das gesetzlich maximal zulässige Maß beschränkt.
            </p>


            <Header as="h2">Abschnitt 15 - Entschädigung</Header>
            <p>
                Sie verpflichten sich, Hirnstiff Abdulhanan Oesterle Schroeder GbR und unsere Muttergesellschaft,
                Tochtergesellschaften, verbundenen Unternehmen, Partner, leitenden Angestellten, Führungskräfte,
                Vertreter, Auftragnehmer, Lizenzgeber, Dienstleister, Subunternehmer, Lieferanten, Praktikanten und
                Mitarbeiter im Zusammenhang mit Ansprüchen oder Forderungen, einschließlich angemessener Anwaltskosten,
                die von Dritten aufgrund Ihrer Verletzung dieser Allgemeinen Geschäftsbedingungen oder der Dokumente,
                die durch Bezugnahme darauf Bestandteil derselben sind, oder Ihrer Verletzung eines Gesetzes oder der
                Rechte eines Dritten geltend gemacht werden, zu entschädigen, zu verteidigen und schadlos zu halten.
            </p>


            <Header as="h2">Abschnitt 16 - Salvatorische Klausel</Header>
            <p>
                Sollte sich herausstellen, dass eine Bestimmung dieser Allgemeinen Geschäftsbedingungen rechtswidrig,
                nichtig oder nicht durchsetzbar ist, ist diese Bestimmung dennoch im gesetzlich zugelassenen Umfang
                durchsetzbar, und der nicht durchsetzbare Teil gilt als von diesen Allgemeinen Geschäftsbedingungen
                getrennt, wobei eine solche Festlegung keine Auswirkungen auf die Gültigkeit und Durchsetzbarkeit der
                übrigen Bestimmungen hat.
            </p>


            <Header as="h2">Abschnitt 17 - Kündigung</Header>
            <p>
                Die Verpflichtungen und Verbindlichkeiten der Parteien, die vor dem Kündigungsdatum entstanden sind,
                überdauern die Kündigung dieser Vereinbarung für alle Zwecke.
            </p>
            <p>
                Diese Allgemeinen Geschäftsbedingungen sind so lange gültig, bis sie von Ihnen oder uns gekündigt
                werden. Sie können diese Allgemeinen Geschäftsbedingungen jederzeit kündigen, indem Sie uns mitteilen,
                dass Sie unsere Dienste nicht mehr nutzen möchten, oder wenn Sie die Nutzung unserer Website einstellen.
            </p>
            <p>
                Wenn Sie es nach unserem alleinigen Ermessen versäumen, irgendeine Bestimmung dieser Allgemeinen
                Geschäftsbedingungen zu erfüllen, oder wir dies vermuten, können wir diese Vereinbarung jederzeit
                fristlos kündigen, und Sie bleiben für alle fälligen Beträge bis einschließlich Kündigungsdatum
                haftbar, und/oder wir verweigern Ihnen den Zugang zu unseren Serviceleistungen (oder Teilen davon).
            </p>


            <Header as="h2">Abschnitt 18 - Gesamte Vereinbarung</Header>
            <p>
                Das Versäumnis unsererseits, ein Recht oder eine Bestimmung dieser Allgemeinen Geschäftsbedingungen
                auszuüben oder durchzusetzen, stellt keinen Verzicht auf dieses Recht oder diese Bestimmung dar.
            </p>
            <p>
                Diese Allgemeinen Geschäftsbedingungen sowie von uns auf dieser Website oder in Bezug auf die
                Serviceleistung veröffentlichten Richtlinien oder Betriebsvorschriften, stellen die gesamte
                Vereinbarung und Übereinkunft zwischen Ihnen und uns dar, regeln Ihre Nutzung der Serviceleistung und
                ersetzen alle vorherigen oder gleichzeitigen Vereinbarungen, Mitteilungen und Angebote, mündlich oder
                schriftlich, zwischen Ihnen und uns (einschließlich, aber nicht beschränkt auf vorherige Versionen der
                Allgemeinen Geschäftsbedingungen).
            </p>
            <p>
                Etwaige Unklarheiten bei der Auslegung dieser Allgemeinen Geschäftsbedingungen sind nicht gegen die
                verfassende Partei zu verstehen.
            </p>


            <Header as="h2">Abschnitt 19 - Geltendes Recht</Header>
            <p>
                Diese Allgemeinen Geschäftsbedingungen und alle separaten Vereinbarungen, in deren Rahmen wir Ihnen
                Serviceleistungen zur Verfügung stellen, unterliegen den Gesetzen von
            </p>
            <p>
                Im Mönchgraben 26 / 1<br/>
                75397 Simmozheim<br/>
                Deutschland<br/>
            </p>
            <p>
                und sind nach diesen auszulegen.
            </p>


            <Header as="h2">Abschnitt 20 - Änderungen der Allgemeinen Geschäftsbedingungen</Header>
            <p>
                Die aktuelle Version der Allgemeinen Geschäftsbedingungen können Sie jederzeit auf dieser Seite
                einsehen.
            </p>
            <p>
                Wir behalten uns das Recht vor, jegliche Teile dieser Allgemeinen Geschäftsbedingungen nach eigenem
                Ermessen zu aktualisieren, zu ändern oder zu ersetzen, indem wir Aktualisierungen und Änderungen auf
                unserer Website veröffentlichen. Es liegt in Ihrer Verantwortung, unsere Website regelmäßig auf
                mögliche Änderungen zu überprüfen. Indem Sie unsere Website oder die Serviceleistung nach der
                Veröffentlichung von Änderungen dieser Allgemeinen Geschäftsbedingungen weiterhin nutzen oder
                weiterhin darauf zugreifen, gilt dies als Annahme dieser Änderungen.
            </p>


            <Header as="h2">Abschnitt 21 - Kontaktdaten</Header>
            <p>
                Fragen zu den Allgemeinen Geschäftsbedingungen sind unter info@knopfdruck.eu an uns zu richten.
            </p>
        </Container>
    </PageTemplate>;
}
