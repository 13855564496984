import config from "../config/config";
import { PrintOptionsState } from "./print";

export type VariantState = {
    /** Unique identifier to the variant */
    id: string;
    /** Unique identifier of the design the variant belongs to */
    designId: string;
    /** How often the design should be printed using the currently selected `PrintOptions` */
    quantity: number;
    /** The currently selected `PrintOptions` for the variant */
    printOptions: PrintOptionsState;
    /**
     * The price of a single variant
     * (the total price is calculated by multiplying this field with the `quantity` field)
     */
    pricePerPiece: number | undefined;
    /** Whether or not the user wants to edit a certain variant and the `SpecificationEdit` Component should be shown */
    editMode: boolean;
    /** Whether or not the variant is currently being deleted */
    isDeleting: boolean;

    /** An error message which is displayed to the user, if applicable */
    error: string | null;

    /** A user can add a note to further specify any information */
    customerNote: string | null;
}

/**
 * @param variant - The variant to check
 * @returns Whether or not the variant is valid
 */
export function variantIsValid (variant: VariantState): boolean {
    if (variant.isDeleting) {
        return false;
    }

    if (variant.error !== null) {
        return false;
    }

    if (!variant.pricePerPiece) {
        return false;
    }

    return true;
}


/**
 * @returns true if the maximum number of variants of a  design is reached, as defined in the configs.
 */
export function maxVariantsReached (variants: VariantState[]): boolean {
    return variants.length >= config.designs.maxVariantsPerDesign;
}
