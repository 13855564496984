import * as THREE from "three";

/**
 * Factory which is used to calibrate the camera of the canvas, so that the mesh is entirely in the frame
 *
 * @param camera - reference to the camera of the canvas
 */
export function fitCameraFactory (camera: THREE.PerspectiveCamera): (object: THREE.Object3D) => THREE.PerspectiveCamera {

    return (object: THREE.Object3D): THREE.PerspectiveCamera => {

        const boundingBox = new THREE.Box3();
        boundingBox.setFromObject( object );

        const size = new THREE.Vector3();
        boundingBox.getSize(size);


        const fov = camera.fov * ( Math.PI / 180 );
        const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
        const dx = size.z / 2 + Math.abs( size.x / 2 / Math.tan( fovh / 2 ) );
        const dy = size.z / 2 + Math.abs( size.y / 2 / Math.tan( fov / 2 ) );
        const cameraZ = Math.max(dx, dy);

        camera.position.set( 0, 0, cameraZ );

        const minZ = boundingBox.min.z;
        const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

        camera.far = cameraToFarEdge * 3;
        camera.updateProjectionMatrix();

        return camera;
    };
}
