import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Placeholder } from "semantic-ui-react";

import { RootState } from "../../redux/store";
import { formatPrice } from "../../utils/price";

export default function Price (props: { variantId: string, totalPrice: boolean, showQuantity: boolean}): ReactElement {
    const variant = useSelector((state: RootState) => state.variants[props.variantId]);


    if (props.showQuantity) {
        if (!variant?.pricePerPiece && !variant.error) {
            return (
                <>
                    {variant.quantity} x <span> </span>
                    <Placeholder as="span">
                        <span style={{ visibility: "hidden" }}> --,-- €</span>
                    </Placeholder>
                </>
            );
        }

        return (
            <>
                {
                    <span>
                        {variant.quantity} x {formatPrice(variant?.pricePerPiece || 0)}
                    </span>

                }
            </>
        );
    }
    if (!variant?.pricePerPiece && !variant.error) {
        return (
            <>
                <Placeholder as="span">
                    <span style={{ visibility: "hidden" }}> --,-- €</span>
                </Placeholder>
            </>
        );
    }

    if (props.totalPrice) {
        return (
            <>
                {
                    <span>
                        {formatPrice(variant.quantity * (variant?.pricePerPiece || 0))}
                    </span>
                }
            </>
        );
    }

    return (
        <>
            {
                <span>
                    {formatPrice(variant?.pricePerPiece || 0)}
                </span>
            }
        </>
    );
}
