/* eslint-disable react/no-unknown-property */
import { Html } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { ReactElement } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Placeholder } from "semantic-ui-react";
interface CanvasProps {
    camera: THREE.PerspectiveCamera;
    children: React.ReactNode;
}

export function FallbackPreview (): ReactElement {
    return    (
        <Html
            zIndexRange={[ 0, 0 ]}
        >
            <Placeholder style={
                {
                    height: "150px",
                    width: "300px",
                    maxWidth: "100%",
                }}>
                <Placeholder.Image square />
            </Placeholder>
        </Html>
    );
}

/**
 * Canvas to render the demo models for the home page
 */
export default function DemoCanvas ({ camera, children }: CanvasProps): ReactElement {
    return (
        <ErrorBoundary fallback={<FallbackPreview/>}>
            <Canvas
                camera={camera}
            >
                { children }
                <ambientLight
                    color={0xffffff}
                    intensity={Math.PI}
                />
                <directionalLight
                    position={[ 0, 100, 0 ]}
                    intensity={Math.PI / 2}
                />
            </Canvas>
        </ErrorBoundary>
    );
}
