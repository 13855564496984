import React, { ReactElement } from "react";
import { Container } from "semantic-ui-react";

import VariantPageBackButton from "./VariantPageBackButton";
import VariantPageCheckoutButton from "./VariantPageCheckoutButton";


/**
 * Handles the controlls in the side menu, when the user is on the variant page.
 *
 * @returns The variant controll component
 */
export default function VariantPageControlls (): ReactElement {
    return (
        <Container textAlign="right">
            <VariantPageBackButton/>
            <VariantPageCheckoutButton/>
        </Container>
    );
}
