import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";

import { RootState } from "../../redux/store";
import DesignPageControlls from "./DesignPageControlls";
import OrderSummary from "./OrderSummary";
import VariantPageControlls from "./VariantPageControlls";

/**
 * Represents the side menu on the page, which includes navigation options and the order summary.
 *
 * @returns The side menu component
 */
export default function SideMenu (): ReactElement {
    const order = useSelector((state: RootState) => state.order);


    return (
        <Container
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >

            <OrderSummary/>

            {order.step === "DESIGNS" ? (<DesignPageControlls/>) : (<></>)}
            {order.step === "VARIANTS" ? (<VariantPageControlls/>) : (<></>)}

        </Container>
    );
}
