/* eslint-disable react/no-unknown-property */
import { useFrame, useLoader } from "@react-three/fiber";
import React, {
    ReactElement, useMemo, useRef,
} from "react";
import * as THREE from "three";
import {
    Mesh, MeshStandardMaterial, Object3D,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function DemoMesh (props: {fitCamera: (object: THREE.Object3D) => void }): ReactElement {
    const meshRef = useRef<THREE.Mesh>();

    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.z += 0.01;
        }
    });

    const defaultMaterial = {
        color: "#46bfc3",
        emissive: "black",
        roughness: 1,
        metalness: 0.5,
        flatShading: true,
    };

    const object: THREE.Object3D | undefined = useMemo(() => {
        const gltf = useLoader(GLTFLoader, "/models/glb/3DBenchy.glb");

        if (!gltf) {
            return undefined;
        }

        const object = gltf.scene;

        if (!object) {
            return undefined;
        }

        object.rotation.x = -Math.PI / 2;
        object.rotation.z = -Math.PI * 3 / 4;
        object.rotation.x += Math.PI / 8;

        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        object.position.sub(center);


        object.traverse((child: Object3D) => {
            if (child?.type === "Mesh") {
                (child as Mesh).material = new MeshStandardMaterial(defaultMaterial);
            }
        });

        props.fitCamera(object);

        return object;
    }, []);

    if (!object) {
        return <></>;
    }

    return <primitive
        ref={meshRef}
        object={object}
    />;
}
