import React, { ReactElement } from "react";
import { Container, Header } from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";

export default function RightsOfWithdrawal (): ReactElement {
    return <PageTemplate>
        <Container
            text
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >
            <Header as="h1">Widerrufsbelehrung</Header>
            <Header as="h2">Widerrufsbelehrung für Verbraucher</Header>


            <p>
                {"\""}Verbraucher{"\""} im Sinne dieser Widerrufsbelehrung ist jede natürliche Person, die ein
                Rechtsgeschäft zu Zwecken abschließt, der überwiegend weder ihrer gewerblichen noch ihrer
                selbstständigen beruflichen Tätigkeit zugerechnet werden können.
            </p>
            <p>
                Sie haben das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.
            </p>
            <p>
                Die Widerrufsfrist beträgt 14 Tage ab dem Tag, an dem Sie oder ein von Ihnen benannter Dritter, der
                nicht der Beförderer ist, die letzte Ware in Besitz genommen haben bzw. hat.
            </p>
            <p>
                Um Ihr Widerrufsrecht auszuüben, müssen Sie uns
            </p>
            <p>
                Hirnstiff Abdulhanan Oesterle Schroeder GbR<br/>
                Im Mönchgraben 26/1<br/>
                75397 Simmozheim<br/>
                E-Mail: info@knopfdruck.eu
            </p>
            <p>
                mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder eine E-Mail) über
                Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das verlinkte <a
                    href="https://cdn.shopify.com/s/files/1/0658/8720/7691/files/Muster_Widerrufsformular.pdf?v=1676231741"
                    target="_blank"
                    rel="noreferrer"
                >
                    Muster-Widerrufsformular
                </a> verwenden, das jedoch nicht vorgeschrieben ist.
            </p>
            <p>
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des
                Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </p>
            <p>
                <strong>Folgen des Widerrufs</strong>
            </p>
            <p>
                Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben,
                einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass
                Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt
                haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die
                Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
                verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben,
                es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
                wegen dieser Rückzahlung Entgelte berechnet.
            </p>
            <p>
                Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den
                Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
                Zeitpunkt ist.
            </p>
            <p>
                Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem
                Sie uns über den Widerruf dieses Vertrags unterrichten, an uns zurückzusenden oder zu übergeben. Die
                Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
            </p>
            <p>
                Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
            </p>
            <p>
                Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn dieser Wertverlust auf einen
                zur Prüfung der Beschaffenheit, Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang
                mit ihnen zurückzuführen ist.
            </p>
            <p>
                <strong>Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts</strong>
            </p>
            <p>
                Das Widerrufsrecht besteht nicht bei Verträgen zur Lieferung von Waren, die nicht vorgefertigt sind und
                für deren Herstellung eine individuelle Auswahl oder Bestimmung durch den Verbraucher maßgeblich ist
                oder die eindeutig auf die persönlichen Bedürfnisse des Verbrauchers zugeschnitten sind.
            </p>
            <p>
                <a
                    href="https://datenschutz-generator.de/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Erstellt mit dem Datenschutz-Generator.de von Dr. Thomas Schwenke
                </a>
            </p>
        </Container>
    </PageTemplate>;
}
