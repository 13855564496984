import React, { ReactElement, ReactNode } from "react";
import { Container, Header } from "semantic-ui-react";


/**
 * The User Guide consists of multiple steps, this component configures a single component.
 *
 * @param header - Header of the step
 * @param content - Content of the step
 * @param imgSrc - Src of the image to be displayed
 * @returns Single user guide step component
 */
export default function UserGuideStep (props: {header: string, content: ReactNode, imgSrc: string}): ReactElement {
    return (


        <Container fluid>
            <Header as="h2"
                content={props.header}/>

            {/* <Image
                centered
                size="large"
                src={props.imgSrc}
            /> */}

            <Container>
                {props.content}
            </Container>

        </Container>
    );
}
