import config from "../config/config";

// TODO missing unit tests
/**
 * Takes a `File` object and returns file name without extension
 *
 * @param file - Input file from html input tag event
 * @returns File Name without Extension
 */
export function getFileNameWithoutExtension (fileName: string): string {
    if (!fileName)
        return "";

    if (fileName && fileName !== "") {
        const lastDotIndex = fileName.lastIndexOf(".");
        return fileName.substring(0, lastDotIndex);
    }

    return "";
}

/**
 * Takes a `File` object and returns extension
 *
 * **Note:** Extension is returned in lowercase and without the dot
 *
 * @param file - Input file from html input tag event
 * @returns File Extension
 */
export function getFileExtension (fileName: string): string {
    if (!fileName)
        return "";

    if (fileName && fileName !== "") {
        if (fileName.indexOf(".") === -1) {
            return "";
        }

        const lastDotindex = fileName.lastIndexOf(".");
        return fileName.substring(lastDotindex + 1, fileName.length).toLowerCase();
    }

    return "";
}

/**
 * Format File Size with proper byte extension
 * Example: "10 MB", "1.15 GB"
 *
 * @param file_size - File size in bytes
 * @returns Formatted string including bytes extension
 */
export function formatFileSize (file_size: number): string {
    const sufixes = [ "B", "kB", "MB", "GB", "TB" ];

    // ? if file size exceeds TB, index is set to 4 to cover unlikely case of files larger than 1 PB
    const index = Math.min(Math.floor(Math.log(file_size) / Math.log(1024)), 4);

    return `${ (file_size / Math.pow(1024, index)).toFixed(2) } ${ sufixes[index] }`;
}

/**
 * Checks if a file is not larger than the max file limit, as defined in [config.ts](../config/config.ts)
 *
 * @param file - file to validate
 * @returns Whether or not file is not too large
 */
export function fileSizeIsValid (file: File): boolean {
    return file.size <= config.files.maxSize;
}

/**
 * Checks if the `file.name` contains an allowed extension, as defined in [config.ts](../config/config.ts)
 *
 * @param file - file to validate
 * @returns Whether or not the extensions is valid
 */
export function fileExtensionIsValid (fileName: string): boolean {
    return config.files.extensions.includes(
        `.${ getFileExtension(fileName).toLowerCase() }`,
    );
}

/**
 * The file alias is generated by removing all characters that are not alphanumeric, underscore,
 * dash or dot and * limits it to `utf-8` encoding. Additionally, the alias is limited to 255 characters.
 *
 * @param file - File to get sanitized file name for
 * @returns Sanitized file name without extension and any special characters
 */
export function getAlias (fileName: string): string {
    const fileNameWithoutExtension = getFileNameWithoutExtension(fileName);
    const extension = getFileExtension(fileName).toLowerCase();

    let alias = fileNameWithoutExtension.split("").filter(char =>{
        return char.match(/[a-zA-Z0-9_\-.]/);
    },
    ).join("").trim();

    // Lowercase the file extension
    alias = `${ alias }.${ extension }`;

    // Limit the alias to 255 characters
    if (alias.length > 255) {
        return alias.substring(0, 255);
    }

    return alias;

}
