import {
    CaseReducer, createSlice, PayloadAction,
} from "@reduxjs/toolkit";

import { DesignState, Dimensions } from "../../states/design";
import { CompatiblePrintOptions } from "../../states/print";

// ? key is the file alias of the design, the id is irrelevant to the user
type DesignReducerType = { [fileAlias: string]: DesignState};
const initialState: DesignReducerType = {};

// Design Reducers --------------------
const add: CaseReducer<DesignReducerType, PayloadAction<DesignState>> = (state, { payload: designState }) => {
    return {
        ...state,
        [designState.fileAlias]: designState,
    };
};

const initExistingDesigns: CaseReducer<DesignReducerType, PayloadAction<DesignState[]>> = (state, { payload: designs }) => {
    const newState: DesignReducerType = designs.reduce((acc, design) => ({
        ...acc,
        [design.fileAlias]: design,
    }), {});
    return newState;
};

const deleteDesign: CaseReducer<DesignReducerType, PayloadAction<string>> = (state, { payload: fileAlias }) => {
    const newState = { ...state };
    delete newState[fileAlias];

    return newState;
};
// ------------------------------------

// Set preview url --------------------
const setPublicUrl: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, publicUrl: string}>> =
    (state, { payload: { fileAlias, publicUrl } }) => {
        const oldDesign = state[fileAlias];

        return {
            ...state,
            [fileAlias]: {
                ...oldDesign,
                publicUrl,
            },
        };
    };
// ------------------------------------

// Set Dimensions ---------------------
const setDimensions: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, dimensions: Dimensions}>> = (state, { payload: { fileAlias, dimensions } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            dimensions,
        },
    };
};
// ------------------------------------

// Set compatible options -------------
const setCompatiblePrintOptions: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, compatiblePrintOptions: CompatiblePrintOptions}>> = (state, { payload: { fileAlias, compatiblePrintOptions } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            compatiblePrintOptions,
        },
    };
};
// ------------------------------------

// Error Reducers ---------------------
const setError: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, error: string}>> = (state, { payload: { fileAlias, error } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            error,
        },
    };
};

const setWarning: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, warning: string}>> = (state, { payload: { fileAlias, warning } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            warning,
        },
    };
};

const clearWarning: CaseReducer<DesignReducerType, PayloadAction<string>> = (state, { payload: fileAlias }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            warning: null,
        },
    };
};

// ------------------------------------

const setIsUploading: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, isUploading: boolean}>> = (state, { payload: { fileAlias, isUploading } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            isUploading,
        },
    };
};

const increaseUploadProgress: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, progressIncrease: number}>> = (state, { payload: { fileAlias, progressIncrease } }) => {
    const oldDesign = state[fileAlias];
    const oldProgress = oldDesign.uploadProgress ?? 0;

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            uploadProgress: oldProgress + progressIncrease >= 100 ? 100 : oldProgress + progressIncrease,
        },
    };
};

const setIsDeleting: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, isDeleting: boolean}>> = (state, { payload: { fileAlias, isDeleting } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            isDeleting,
        },
    };
};

const clear: CaseReducer<DesignReducerType> = () => {
    return {
        ...initialState,
    };
};

const setIsSlicing: CaseReducer<DesignReducerType, PayloadAction<{fileAlias: string, isSlicing: boolean}>> = (state, { payload: { fileAlias, isSlicing } }) => {
    const oldDesign = state[fileAlias];

    return {
        ...state,
        [fileAlias]: {
            ...oldDesign,
            isSlicing,
        },
    };
};

const update: CaseReducer<DesignReducerType, PayloadAction<DesignState>> = (state, { payload: designState }) => {
    return {
        ...state,
        [designState.fileAlias]: designState,
    };
};

export const designsSlice = createSlice({
    name: "designs",
    initialState,
    reducers: {
        addDesign: add,
        update,
        initExistingDesigns,
        deleteDesign,
        setPublicUrl,
        setDimensions,
        setCompatiblePrintOptions,
        setError,
        setWarning,
        clearWarning,
        setIsUploading,
        increaseUploadProgress,
        clear,
        setIsSlicing,
        setIsDeleting,
    },
});

export const designStore = designsSlice.actions;

export default designsSlice.reducer;
