import React, { ReactElement } from "react";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

interface PageTemplateProps {
    children: React.ReactNode;
}

/**
 * Template which contains the navbar & footer
 */
export default function PageTemplate ({ children }: PageTemplateProps): ReactElement {
    return (
        <div
            style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
        >
            <Navbar/>
            { children }

            {/* ? Spacing component to force footer to the bottom */}
            <div style={{ flex: 1 }}></div>
            <Footer />
        </div>
    );
}
