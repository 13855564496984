/* eslint-disable react/no-unknown-property */
import { Html } from "@react-three/drei";
import {
    Canvas,
    useLoader,
} from "@react-three/fiber";
import React, {
    ReactElement, Suspense, useMemo,
} from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import {
    Item, Placeholder,
    Segment,
} from "semantic-ui-react";
import * as THREE from "three";
import {
    Mesh, MeshStandardMaterial, Object3D,
} from "three";
import { ThreeMFLoader } from "three/examples/jsm/loaders/3MFLoader";
import { AMFLoader } from "three/examples/jsm/loaders/AMFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

import config from "../../config/config";
import { DesignState } from "../../states/design";
import { getFileExtension } from "../../utils/file";
import { fitCameraFactory } from "../../utils/three";


type PreviewCanvasProps = {
    camera: THREE.PerspectiveCamera;
    children?: React.ReactNode; // This line allows children to be passed to PreviewCanvas
  };


/**
 * Renders the Design as a three js ojbect
 *
 * @param design - Design to render
 * @param size - Size of the preview html component
 * @param height - height of the preview
 * @param width - width of the preview
 *
 * @returns Component
 */
export default function DesignPreview (props :
    {
        fileAlias: string,
        size: {height: number | undefined, width: number | undefined}
    },
): ReactElement {
    const design = useSelector((state: {designs: {[key: string]: DesignState}}) => state.designs[props.fileAlias]);

    if (!design) {
        return <FallbackPreview />;
    }

    const camera = useMemo(() => {return new THREE.PerspectiveCamera( 20, 1, 10, 250 );}, [ design.publicUrl ]);

    if (design?.error) {
        return <FallbackPreview />;
    }

    if (design.isUploading || !design.publicUrl) {
        return (
            <Placeholder style={{
                height: props.size.height,
                width: props.size.width,
                maxHeight: "100%",
                maxWidth: "100%",
                marginRight: "21px",
            }}>
                <Placeholder.Image square />
            </Placeholder>
        );
    } else if (design.publicUrl) {
        return (
            <Segment
                textAlign="center"
                basic
                style={{
                    height: props.size.height,
                    width: props.size.width,
                    maxHeight: "100%",
                    maxWidth: "100%",
                    padding: 0,
                    marginRight: "21px",
                }}>

                <PreviewCanvas camera={camera}>
                    <Suspense fallback={
                        <Html
                            zIndexRange={[ 0, 0 ]}
                        >
                            <Placeholder style={
                                {
                                    height: props.size.height,
                                    width: props.size.width,
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                }}>
                                <Placeholder.Image square />
                            </Placeholder>
                        </Html>
                    }>
                        <DesignModel
                            fileAlias={design.fileAlias}
                            publicUrl={design.publicUrl}
                            fitCamera={fitCameraFactory(camera)}
                        />
                    </Suspense>
                </PreviewCanvas>
            </Segment>
        );
    }

    return <FallbackPreview />;
}

function FallbackPreview (): ReactElement {
    return (
        <Item.Image
            size="small"
            src={config.designs.defaultPreviewUrl}
        />
    );
}


/**
 * Component to load and render the design
 *
 * @param fileAlias - alias of the design file
 * @param publicUrl - endpoint of where to load the design from
 * @param fitCamera - factory to fit the camera params, which contains a reference to the camera of the canvas
 */
function DesignModel (props: {fileAlias: string, publicUrl: string, fitCamera: (object: THREE.Object3D) => void }): ReactElement {
    const extension = getFileExtension(props.fileAlias);

    const defaultMaterial = {
        color: "#46bfc3",
        emissive: "black",
        roughness: 1,
        metalness: 0.5,
        flatShading: true,
    };

    const object: THREE.Object3D | undefined = useMemo(() => {
        let object: THREE.Object3D | undefined = undefined;
        if (extension === "obj") {
            object =  useLoader(OBJLoader, props.publicUrl);
        } else if (extension === "3mf") {
            object =  useLoader(ThreeMFLoader, props.publicUrl);
        } else if (extension === "stl" || extension === "stp" || extension === "step") {
            const geometry = useLoader(STLLoader, props.publicUrl);
            if (!geometry) {return undefined;}
            const material = new MeshStandardMaterial(defaultMaterial);
            object =  new Mesh(geometry as THREE.BufferGeometry<THREE.NormalBufferAttributes>, material);
        } else if (extension === "amf") {
            object = useLoader(AMFLoader, props.publicUrl);
        }

        if (!object) {
            return undefined;
        }

        object.rotation.x = -Math.PI / 2;
        object.rotation.z = -Math.PI * 3 / 4;
        object.rotation.x += Math.PI / 8;

        const box = new THREE.Box3().setFromObject(object);
        const center = box.getCenter(new THREE.Vector3());
        object.position.sub(center);


        object.traverse((child: Object3D) => {
            if (child?.type === "Mesh") {
                (child as Mesh).material = new MeshStandardMaterial(defaultMaterial);
            }
        });

        props.fitCamera(object);

        return object;
    }, [ props.publicUrl ]);


    if (!extension) {
        return <></>;
    }

    if (!props.publicUrl) {
        return <></>;
    }

    if (!object) {
        return <></>;
    }

    return <primitive
        object={object}
    />;
}

/**
 * Canvas Component for the design to render
*/
export function PreviewCanvas ({ camera, children }: PreviewCanvasProps): ReactElement {
    return (
        <ErrorBoundary fallback={<FallbackPreview />}>
            <Canvas
                frameloop="demand"
                camera={camera}
            >
                { children }
                <ambientLight
                    color={0xffffff}
                    intensity={Math.PI}
                />
                <directionalLight
                    position={[ 0, 100, 0 ]}
                    intensity={Math.PI / 2}
                />
            </Canvas>
        </ErrorBoundary>
    );
}

