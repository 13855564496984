import React, { ReactElement } from "react";
import { Container, Header } from "semantic-ui-react";

import PageTemplate from "../../templates/PageTemplate";

export default function Imprint (): ReactElement {
    return <PageTemplate>
        <Container
            text
            style={{
                marginTop: "60px",
                paddingBottom: "60px",
            }}
        >
            <Header as="h1">Impressum</Header>
            <p>Angaben gemäß § 5 TMG</p>
            <Header as="h2">Hirnstiff Abdulhanan Oesterle Schroeder GbR</Header>
            <p>
                Im Mönchgraben 26 / 1 <br/>
                75397 Simmozheim
            </p>
            <p>E-Mail: info@knopfdruck.eu</p>
            <p>
                <strong>Vertreten durch:</strong><br/>
                Mehamed Abdulhanan <br/>
                Theo Oesterle <br/>
                Nick B. Schroeder
            </p>
            <p>
                <strong>Umsatzsteuer-ID:</strong><br/>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: <br/>
                DE357617678
            </p>
            <p>
                <strong>EU-Streitschlichtung</strong> <br/>
                Die Europäische Kommission stellt
                <a
                    href="https://ec.europa.eu/consumers/odr/"
                    target="_blank"
                    rel="noreferrer"
                > eine Plattform
                </a> (https://ec.europa.eu/consumers/odr/)
                zur Online-Streitbeilegung (OS) bereit: <br/>
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <p>
                <strong>Verbraucherstreitbeilegung / Universalschlichtungsstelle</strong><br/>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
            </p>

            <a
                href="https://www.e-recht24.de/impressum-generator.html"
                target="_blank"
                rel="noreferrer"
            >
                Quelle
            </a>
        </Container>
    </PageTemplate>;
}
