import {
    CaseReducer, createSlice, PayloadAction,
} from "@reduxjs/toolkit";

import { PrintOptionsState } from "../../states/print";
import { VariantState } from "../../states/variant";

// ? key is the variant id, defined by the backend
type VariantReducerType = { [id: string]: VariantState};
const initialState: VariantReducerType = {};

// Variant Reducers -------------------
const add: CaseReducer<VariantReducerType, PayloadAction<VariantState>> = (state, { payload: variant }) => {
    return {
        ...state,
        [variant.id]: variant,
    };
};

const deleteVariant: CaseReducer<VariantReducerType, PayloadAction<string>> = (state, { payload: variantId }) => {
    const { [variantId]: _, ...rest } = state;
    return rest;
};

const initExistingVariants: CaseReducer<VariantReducerType, PayloadAction<VariantState[]>> = (state, { payload: variants }) => {
    const newState: VariantReducerType = {};
    for (const variant of variants) {
        newState[variant.id] = variant;
    }

    return {
        ...newState,
    };
};
// ------------------------------------

// Price Reducer ----------------------
const setPrice: CaseReducer<VariantReducerType, PayloadAction<{variantId: string, pricePerPiece: number| undefined}>> = (state, { payload: { variantId, pricePerPiece } }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            pricePerPiece,
        },
    };
};

const unsetPrice: CaseReducer<VariantReducerType, PayloadAction<string>> = (state, { payload: variantId }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            price: undefined,
        },
    };
};
// ------------------------------------

// Edit Mode --------------------------
const toggleEditMode: CaseReducer<VariantReducerType, PayloadAction<string>> = (state, { payload: variantId }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            editMode: !oldVariant.editMode,
        },
    };
};

const setIsDeleting: CaseReducer<VariantReducerType, PayloadAction<{variantId: string, isDeleting: boolean}>> = (state, { payload: { variantId, isDeleting } }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            isDeleting,
        },
    };
};
// ------------------------------------

// Error Fields -----------------------
const setError: CaseReducer<VariantReducerType, PayloadAction<{variantId: string, error: string}>> = (state, { payload: { variantId, error } }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            error,
        },
    };
};

const clearError: CaseReducer<VariantReducerType, PayloadAction<string>> = (state, { payload: variantId }) => {
    const oldVariant = state[variantId];

    return {
        ...state,
        [variantId]: {
            ...oldVariant,
            error: null,
        },
    };
};
// ------------------------------------

// Specifications ---------------------

const setSpecifications: CaseReducer<VariantReducerType, PayloadAction<{variantId: string, quantity: number, printOptions: PrintOptionsState, customerNote: string}>> =
    (state, { payload: { variantId, quantity, printOptions, customerNote } }) => {
        const oldVariant = state[variantId];

        return {
            ...state,
            [variantId]: {
                ...oldVariant,
                quantity,
                printOptions,
                customerNote,
            },
        };
    };

// ------------------------------------

const clear: CaseReducer<VariantReducerType> = () => {
    return {
        ...initialState,
    };
};

const deleteDesignVariants: CaseReducer<VariantReducerType, PayloadAction<string>> = (state, { payload: designId }) => {
    const newState: VariantReducerType = {};
    for (const variantId in state) {
        if (state[variantId].designId !== designId) {
            newState[variantId] = state[variantId];
        }
    }

    return newState;
};


export const variantSlice = createSlice({
    name: "variants",
    initialState,
    reducers: {
        addVariant: add,
        deleteVariant,
        initExistingVariants,
        setPrice,
        unsetPrice,
        toggleEditMode,
        setError,
        clearError,
        clear,
        deleteDesignVariants,
        setSpecifications,
        setIsDeleting,
    },
});

export const variantStore = variantSlice.actions;


export default variantSlice.reducer;
